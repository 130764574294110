/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query'
import {
  getDataToDashboard,
  getDashboardPartnersActive,
  getDashboardTotalAmountEducation,
  getDashboardTotalAmountHealth,
  getDashboardTotalAmountAttendence
} from '../api/dashboard'

const key = 'dashboard'

export function useDashboard(filters) {
  return useQuery([key, filters], () => getDataToDashboard(filters), {
    refetchOnWindowFocus: false
  })
}

export function useDashboardPartners() {
  return useQuery([key, 'partners'], () => getDashboardPartnersActive(), {
    refetchOnWindowFocus: false
  })
}

export function useDashboardTotalAmountEducation() {
  return useQuery(
    [key, 'TotalAmountEducation'],
    () => getDashboardTotalAmountEducation(),
    {
      refetchOnWindowFocus: false
    }
  )
}

export function useDashboardTotalAmountHealth() {
  return useQuery(
    [key, 'TotalAmountHealth'],
    () => getDashboardTotalAmountHealth(),
    {
      refetchOnWindowFocus: false
    }
  )
}

export function useDashboardTotalAmountAttendence() {
  return useQuery(
    [key, 'TotalAmountAttendence'],
    () => getDashboardTotalAmountAttendence(),
    {
      refetchOnWindowFocus: false
    }
  )
}
