/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query'
import { getOneAdditional } from '../api/AdditionalInformation'

const key = 'additionalInformation'

export function useAdditionalInformation(
  programId,
  keyStep,
  step,
  documentSelected,
  otherRequestSelected
) {
  return useQuery(
    [key, programId, keyStep, step, documentSelected],
    () =>
      getOneAdditional(
        programId,
        keyStep,
        step,
        documentSelected,
        otherRequestSelected
      ),
    {
      refetchOnWindowFocus: false
    }
  )
}
