/* eslint-disable import/prefer-default-export */
import axios from '../Axios'

const module = '/dashboard'

export const getDataToDashboard = async (filtersPrograms) => {
  const { page, limit, category, types } = filtersPrograms
  const { data } = await axios.get(
    `${module}/?page=${page}&limit=${limit}&category=${category}&types=${types}`
  )
  return data
}

export const getDashboardPartnersActive = async () => {
  const { data } = await axios.get(`${module}/partnersActive`)
  return data
}

export const getDashboardTotalAmountEducation = async () => {
  const { data } = await axios.get(`${module}/totalAmountEducation`)
  return data
}

export const getDashboardTotalAmountHealth = async () => {
  const { data } = await axios.get(`${module}/totalAmountHealth`)
  return data
}

export const getDashboardTotalAmountAttendence = async () => {
  const { data } = await axios.get(`${module}/totalAmountAttendence`)
  return data
}
