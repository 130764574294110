import React from 'react'
import { Grid, GridItem, CircularProgress, Flex } from '@chakra-ui/react'
import CardData from './CardData'
import {
  useDashboardPartners,
  useDashboardTotalAmountAttendence,
  useDashboardTotalAmountHealth,
  useDashboardTotalAmountEducation
} from '../../hooks/Dashboard'

const currencyFormat = new Intl.NumberFormat('es-CL', { currency: 'CLP' })

const TotalAmountDashboard = ({ data, approveLoading }) => {
  const { data: partnersActive, isLoading: partnersLoading } =
    useDashboardPartners()

  const { data: totalAmountAttendence, isLoading: attendenceLoading } =
    useDashboardTotalAmountAttendence()

  const { data: totalAmountHealth, isLoading: healthLoading } =
    useDashboardTotalAmountHealth()

  const { data: totalAmountEducation, isLoading: educationLoading } =
    useDashboardTotalAmountEducation()

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(4, minmax(0, 1fr))',
        md: 'repeat(1, minmax(0, 1fr))',
        sm: 'repeat(1, minmax(0, 1fr))'
      }}
      gap={2}
    >
      <GridItem>
        {partnersLoading ? (
          <Flex
            direction={'column'}
            p={2}
            bg={'#F5F5F5'}
            borderRadius={12}
            gap={2}
            py={2}
            px={4}
            mb={2}
            align={'center'}
          >
            <CircularProgress isIndeterminate color="green.300" />
          </Flex>
        ) : (
          <CardData
            title={'Socios activos'}
            count={partnersActive?.partnersActive}
            typeCard={'count'}
            mb={2}
          />
        )}
        {approveLoading ? (
          <Flex
            direction={'column'}
            p={2}
            bg={'#F5F5F5'}
            borderRadius={12}
            gap={2}
            py={2}
            px={4}
            align={'center'}
          >
            <CircularProgress isIndeterminate color="green.300" />
          </Flex>
        ) : (
          <CardData
            title={'Solicitudes por aprobar'}
            count={data?.programToApprove?.totalRecords}
            typeCard={'count'}
          />
        )}
      </GridItem>
      <GridItem>
        {attendenceLoading ? (
          <Flex
            direction={'column'}
            p={2}
            bg={'#F5F5F5'}
            borderRadius={12}
            gap={2}
            py={2}
            px={4}
            mb={2}
            align={'center'}
            justify={'center'}
            h={'100%'}
          >
            <CircularProgress isIndeterminate color="green.300" />
          </Flex>
        ) : (
          <CardData
            title={'Asistencia'}
            totalRecords={totalAmountAttendence?.totalApprovedAttendence || 0}
            amount={currencyFormat.format(
              totalAmountAttendence?.totalAmountAttendence || 0
            )}
            colorCard={'#FFDDC7'}
            typeCard={'amount'}
          />
        )}
      </GridItem>
      <GridItem>
        {healthLoading ? (
          <Flex
            direction={'column'}
            p={2}
            bg={'#F5F5F5'}
            borderRadius={12}
            gap={2}
            py={2}
            px={4}
            mb={2}
            align={'center'}
            justify={'center'}
            h={'100%'}
          >
            <CircularProgress isIndeterminate color="green.300" />
          </Flex>
        ) : (
          <CardData
            title={'Salud'}
            totalRecords={totalAmountHealth?.totalApprovedHealth || 0}
            amount={currencyFormat.format(
              totalAmountHealth?.totalAmountHealth || 0
            )}
            colorCard={'#CEF6FF'}
            typeCard={'amount'}
          />
        )}
      </GridItem>
      <GridItem>
        {educationLoading ? (
          <Flex
            direction={'column'}
            p={2}
            bg={'#F5F5F5'}
            borderRadius={12}
            gap={2}
            py={2}
            px={4}
            mb={2}
            align={'center'}
            justify={'center'}
            h={'100%'}
          >
            <CircularProgress isIndeterminate color="green.300" />
          </Flex>
        ) : (
          <CardData
            title={'Educación'}
            totalRecords={totalAmountEducation?.totalApprovedEducation || 0}
            amount={currencyFormat.format(
              totalAmountEducation?.totalAmountEducation || 0
            )}
            colorCard={'#FDF1C5'}
            typeCard={'amount'}
          />
        )}
      </GridItem>
    </Grid>
  )
}

export default TotalAmountDashboard
