import React, { useMemo } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { CardFilePreview } from '../../UI'

const RenderDocument = ({ titleDocument, arrayDocuments }) => {
  const sortedDocuments = useMemo(
    () =>
      [...arrayDocuments].sort((a, b) => new Date(a.date) - new Date(b.date)),
    [arrayDocuments]
  )

  const generateColor = (index, total) => {
    const brightness = 100 - (index / total) * 50 // Adjust the range as needed
    return `hsl(120, 100%, ${brightness}%)`
  }

  return (
    <Flex gap={4} direction={'column'}>
      <Text fontWeight={700}>{titleDocument}</Text>
      {sortedDocuments?.map((item, index) => (
        <CardFilePreview
          key={item?.url}
          name={item?.name}
          url={item?.url}
          highlightColor={generateColor(index, sortedDocuments.length)}
        />
      ))}
    </Flex>
  )
}

export default RenderDocument
