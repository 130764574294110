/* eslint-disable import/prefer-default-export */
import axios from '../Axios'

const module = '/additional-information'

export const getOneAdditional = async (
  programId,
  key,
  step,
  documentSelected,
  subDocumentSelected
) => {
  const { data } = await axios.get(`${module}/get-one`, {
    params: {
      programId,
      key,
      step,
      documentSelected,
      subDocumentSelected
    }
  })
  return data
}
