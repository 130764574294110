import React from 'react'
import { Flex, Icon, Text } from '@chakra-ui/react'
import { FileIcon, ViewIcon } from '../Icons'
import SquareButton from './SquareButton'

const BarFileName = ({
  fileName = null,
  fileUrl = null,
  showDelete = true,
  onDelete,
  highlightColor = 'beige.100'
}) => {
  const validateLargeFileName = () => fileName
  // {
  //   if (fileName.length > 60) {
  //     return `${fileName.substring(0, 60)}...`
  //   }
  //   return fileName
  // }

  return (
    <Flex
      bg={highlightColor}
      justify={'space-between'}
      borderRadius={8}
      borderWidth={1}
      my={1}
      padding={2}
      gap={2}
      opacity={!fileName ? 0 : 1}
      align={'center'}
    >
      <Flex align={'center'}>
        <Icon fontSize="34" as={FileIcon} />
        <Text color={'green.100'} fontWeight={700}>
          {validateLargeFileName()}
        </Text>
      </Flex>
      <Flex align={'center'} gap={1} p={0} m={0}>
        {fileUrl && (
          <SquareButton
            onClick={() => window.open(fileUrl, '_blank')}
            size={'xs'}
          >
            <Icon as={ViewIcon} fontSize={25} />
          </SquareButton>
        )}
        {fileName && showDelete && (
          <SquareButton onClick={onDelete} size={'xs'}>
            X
          </SquareButton>
        )}
      </Flex>
    </Flex>
  )
}

export default BarFileName
